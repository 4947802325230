export default class AppUser {
    static Name = "AppUser";
    static LastName = "";
    static FullName = "AppUser";
    static Role = "";
    static FullAccess = false;
    static LoadUser = (user) => {
        if (user) {
            AppUser.Name = user.name;
            AppUser.LastName = user.lastname;
            AppUser.FullName = user.name + " " + user.lastname;
            AppUser.Role = user.role;
            AppUser.FullAccess = user.fullAccess;
        }
    };
}