import React,{useContext} from 'react'
import Dashboard from './dashboard'

import { context } from './index'

const Content = () => {
    const { state } = useContext(context)
    let Content = null
    if (state.view === 'dashboard') {
        Content = <Dashboard />
    } else if (state.view === 'user') {
        Content = <div>Users</div>
    }
  return (
    <main>{Content}</main>
  )
}

export default Content