import React, { useContext, useReducer } from 'react'
import AppUser from '../../Class/user'
import { Redirect } from 'react-router-dom'
import { initialState, reducer } from './reducer'
import Content from './content'
import mclogo from '../../assets/mclogo.png'

import "./admin.scss"

export const context = React.createContext()

const Dictionary = {
  dashboard: 'Dashboard',
  user: 'Users'
}

const Admin = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  if (AppUser.Role !== 'root') {
    return <Redirect to='/main' />
  }
  return (
    <context.Provider value={{ state, dispatch }}>
      <div id='SymphonyAdmin'>
        <Header />
        <Sidebar {...props} />
        <Content />
      </div>
    </context.Provider>
  )
}

export default Admin

const Sidebar = (props) => {
  const { state, dispatch } = useContext(context)
  const active = state.view
  return (
    <aside>
      <ul>
        <BarButton
          onClick={() => { dispatch({ type: "SET_VIEW", payload: "dashboard" }) }}
          active={active === 'dashboard'}>
          Dashboard
        </BarButton>
        {/*<BarButton
          onClick={() => { dispatch({ type: "SET_VIEW", payload: "user" }) }}
          active={active === 'user'}>
          User
        </BarButton>*/}
        <li
          onClick={() => { props.history.push("/main") }}
        >
          Main
        </li>
      </ul>
    </aside>
  )
}
const BarButton = (props) => {
  return (
    <li
      onClick={props.onClick}
      style={{ backgroundColor: props.active ? '#ffffff1f' : '#ffffff00' }}
    >
      {props.children}
    </li>
  )
}

const Header = () => {
  const { state } = useContext(context)
  return (
    <header>
      <div style={{ "display": "flex", "alignItems": "center", "gap": "10px" }}>
        <img src={mclogo} height={40} alt="MedicareSoft Logo" />
      </div>
      <div style={{ "fontWeight": "bold" }}>{Dictionary[state.view]}</div>
      <div>{AppUser.Name}</div>
    </header>
  )
}