import React from 'react'
import './Cargando.scss'


function Cargando(props) {
    let mensaje = props.mensaje
    if(!mensaje){
        mensaje = 'Validando enlace'
    }
    return (
        <div className="Cargando">
            <div className='Logo'>
                <img src='/IMG/logoletrasC.svg' alt='logo'/>
            </div>
            <div className='mensaje'>
                {mensaje}
            </div>
            <div className="loader">
                <div className="inner one"></div>
                <div className="inner two"></div>
                <div className="inner three"></div>
            </div>
        </div>
    )
}

export default Cargando
