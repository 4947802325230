import axios from 'axios';

class AppAxios {
  /**
   * @type {import('axios').AxiosInstance | null}
   */
  static appaxios = null;

  /**
   * Inicializa la instancia de Axios con la configuración predeterminada.
   * @returns {Promise<void>}
   */
  static async Start() {
    let config = (await axios.get('/configuracion.json')).data;
    this.appaxios = axios.create({
      baseURL: config.ServidorDicom
    });
  }

  /**
   * Realiza una solicitud GET a la URL especificada.
   * @param {string} url - La URL a la que se realizará la solicitud GET.
   * @param {import('axios').AxiosRequestConfig} config - La configuración de la solicitud.
   * @returns {Promise<import('axios').AxiosResponse<any>>}
   */
  static async get(url, config) {
    try{
      return this.appaxios.get(url, config);
    }catch(e){
      console.log(e)
    }
  }

  /**
   * Realiza una solicitud POST a la URL especificada con los datos proporcionados.
   * @param {string} url - La URL a la que se realizará la solicitud POST.
   * @param {any} data - Los datos que se enviarán en la solicitud POST.
   * @returns {Promise<import('axios').AxiosResponse<any>>}
   */
  static async post(url, data) {
    return this.appaxios.post(url, data);
  }
}

export default AppAxios;