import React, { useEffect, useRef, useState } from 'react'
import './dashboard.scss'
import moment from 'moment'
import StudyItem from '../../Class/study'
import createXlsx from '../../functions/xlsx'

const Dashboard = () => {
    const [localstate, setlocalstate] = useState({
        loading: true,
        data: []
    })
    const from = useRef(null)
    const to = useRef(null)
    const getData = async () => {
        setlocalstate({ ...localstate, loading: true })
        let inicio = moment(from.current.value, "YYYY-MM-DD").format('YYYYMMDD')
        let final = moment(to.current.value, "YYYY-MM-DD").format('YYYYMMDD')
        let data = await StudyItem.GetList({ inicio, final })
        setlocalstate({ loading: false, data })
    }
    const createXLSX = () => {
        const anchos = [
            { wpx: 80 },
            { wpx: 250 },
            { wpx: 110 },
            { wpx: 50 },
            { wpx: 100 },
            { wpx: 50 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 80 }
        ]
        let jsondata = [
            ["Id",
            "Paciente", 
            "Fecha de Nacimiento", 
            "Edad", 
            "Fecha de Estudio", 
            "Sexo", 
            "Estudio", 
            "Medico Informante", 
            "Informe"]
        ];
        localstate.data.forEach(item => {
            let informe = item.informLink()===""? "":{ f: `HYPERLINK("${item.informLink()}", "Informe")` }
            jsondata.push([
                item.PAS_ID,
                item.getnombre(),
                item.getBD(),
                item.getage(),
                item.getfecha(),
                item.getsexo(),
                item.getmodalidad(),
                "",
                informe
            ])
        })
        createXlsx("Reporte",jsondata,anchos)
    }
    useEffect(() => {
        from.current.value = moment().startOf("M").format('YYYY-MM-DD')
        to.current.value = moment().endOf("M").format('YYYY-MM-DD')
        getData()
        return () => {
            //second
        }
    }, [])
    return (
        <div className='fullsize dashboard'>
            <div className='filters'>
                <div className='dateFilter'>
                    <span>Desde: <input ref={from} type="date" /></span>
                    <span>Hasta: <input ref={to} type="date" /></span>
                    <button onClick={getData}>Buscar</button>
                </div>
                <ModCounter data={localstate.data} />
            </div>
            <div className='acctions'>
                <button onClick={createXLSX}>Descargar .xlsx</button>
            </div>
            <div className='data'>
                {
                    localstate.loading &&
                    <div>Cargando...</div>
                } {
                    !localstate.loading &&
                    <Tabla data={localstate.data} />
                }
            </div>
        </div>
    )
}

export default Dashboard

const ModCounter = (props) => {
    const data = props.data
    let infcount = 0
    let mods = {}
    data.forEach(item => {
        if (mods[item.getmodalidad()]) {
            mods[item.getmodalidad()]++
        } else {
            mods[item.getmodalidad()] = 1
        }
        if (item.informLink()) {
            infcount++
        }
    })
    return (
        <div className='ModCounter'>
            <span>Total: {data.length}</span>
            <span>Informes: {infcount}</span>
            {
                Object.keys(mods).map((item, index) => {
                    return (
                        <span key={index}>{item}: {mods[item]}</span>
                    )
                })
            }
        </div>
    )
}

const Tabla = (props) => {
    let data = props.data
    if (data.length === 0) {
        return (
            <div>No hay datos</div>
        )
    }
    return (
        <table>
            <thead>
                <tr>
                    <th>Id</th>
                    <th className='PatientName'>Paciente</th>
                    <th>Fecha de Nacimiento</th>
                    <th>Edad</th>
                    <th>Fecha de Estudio</th>
                    <th>Sexo</th>
                    <th>Estudio</th>
                    <th>Medico Informante</th>
                    <th>Informe</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className='PatientID'>{item.PAS_ID}</td>
                                <td className='PatientName'>{item.getnombre()}</td>
                                <td>{item.getBD()}</td>
                                <td>{item.getage()}</td>
                                <td>{item.getfecha()}</td>
                                <td style={{ "textAlign": "center" }}>{item.getsexo()}</td>
                                <td>{item.getmodalidad()}</td>
                                <td></td>
                                <td><a href={item.informLink()} target='_black'>{item.informLink() ? "Link" : ""}</a></td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}