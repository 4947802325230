import React from "react";
/**
 * 
 * @param {*} color
 * @param {*} icon
 *  
 */
function Iconos(props) {
  let color;
  if (props.color === undefined) {
    color = "black";
  } else {
    color = props.color;
  }

  switch (props.icon) {
    case "logout":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="48px"
          height="48px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M14,14l2.8,2.8L11.6,22H32v4H11.6l5.2,5.2L14,34L4,24L14,14z M40,6H24v4h16v28H24v4h16c2.2,0,4-1.8,4-4V10
	C44,7.8,42.2,6,40,6z"
          />
        </svg>
      );
    case "sync":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="48px"
          height="48px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M24,8V2l-8,8l8,8v-6c6.6,0,12,5.4,12,12c0,2-0.5,3.9-1.4,5.6l2.9,2.9c1.6-2.5,2.5-5.4,2.5-8.5C40,15.2,32.8,8,24,8z M24,36
                   c-6.6,0-12-5.4-12-12c0-2,0.5-3.9,1.4-5.6l-2.9-2.9C8.9,17.9,8,20.9,8,24c0,8.8,7.2,16,16,16v6l8-8l-8-8V36z"
          />
        </svg>
      );
    case "noconexion":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="48px"
          height="48px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M47.3,14c-0.9-0.7-9.9-8-23.3-8c-3,0-5.8,0.4-8.3,1l20.7,20.6L47.3,14z M34.1,30.4L6.5,2.9L4,5.4l4.1,4.1
                   c-4.3,2-6.9,4.1-7.4,4.4L24,43l0,0l0,0l7.8-9.7l6.6,6.6l2.5-2.5C41,37.4,34.1,30.4,34.1,30.4z"
          />
        </svg>
      );
    case "cocnexion":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="48px"
          height="48px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M48,18C41.9,11.8,33.4,8,24,8S6.1,11.8,0,18l24,24l0,0l0,0L48,18z M5.8,18.1C11,14.2,17.3,12,24,12s13,2.2,18.2,6.1L39.3,21
	c-4.3-3.1-9.6-5-15.3-5s-11,1.9-15.3,5L5.8,18.1z"
          />
        </svg>
      );
    case "calendario":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="48px"
          height="48px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M18,22h-4v4h4V22z M26,22h-4v4h4V22z M34,22h-4v4h4V22z M38,8h-2V4h-4v4H16V4h-4v4h-2c-2.2,0-4,1.8-4,4l0,28
	c0,2.2,1.8,4,4,4h28c2.2,0,4-1.8,4-4V12C42,9.8,40.2,8,38,8z M38,40H10V18h28V40z"
          />
        </svg>
      );
    case "filtro":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="48px"
          height="48px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M8.5,11.2C12.5,16.4,20,26,20,26v12c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2V26c0,0,7.4-9.6,11.5-14.8c1-1.3,0.1-3.2-1.6-3.2
	H10.1C8.4,8,7.5,9.9,8.5,11.2z"
          />
        </svg>
      );
    case "done":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="48px"
          height="48px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M18,32.4L9.6,24l-2.8,2.8L18,38l24-24l-2.8-2.8L18,32.4z"
          />
        </svg>
      );
    case "WP":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M3.7,43.4C3.7,43.4,3.7,43.4,3.7,43.4c-0.1-0.1,0-0.2,0-0.2c1.1-3.3,2.2-6.6,3.4-10c0.1-0.2,0-0.3,0-0.4
	c-2.5-4.2-3.2-8.7-2.3-13.5c0.9-4.7,3.3-8.6,7.2-11.5c4.5-3.5,9.7-4.7,15.3-3.8c3.7,0.6,7,2.2,9.8,4.7c3.7,3.4,5.9,7.7,6.3,12.7
	c0.2,2,0,4.1-0.4,6c-1,4.2-3.1,7.7-6.4,10.4c-4.6,3.8-9.9,5.2-15.7,4.3c-2.1-0.3-4.2-1-6.1-2c-0.2-0.1-0.3-0.1-0.5,0
	C11.2,41,8.1,42,5,43C4.6,43.1,4.2,43.2,3.7,43.4z M8.6,38.6c0.1,0,0.2,0,0.3-0.1c1.9-0.6,3.9-1.2,5.8-1.9c0.2-0.1,0.3,0,0.4,0.1
	c2.3,1.5,4.9,2.3,7.6,2.5c2.2,0.2,4.3-0.1,6.4-0.8c6.9-2.3,11.7-9,11.2-16.7c-0.2-2.5-0.9-4.9-2.2-7.1c-2.8-4.6-6.9-7.2-12.2-7.9
	c-2-0.3-4-0.1-6,0.4c-3.7,1-6.7,2.9-9,5.9c-2.7,3.6-3.8,7.6-3.2,12.1c0.3,2.6,1.3,4.9,2.8,7.1c0.1,0.1,0.1,0.3,0.1,0.4
	c-0.6,1.9-1.3,3.7-1.9,5.6C8.6,38.4,8.6,38.5,8.6,38.6z M17.5,14.2c-0.5,0-0.9,0.1-1.3,0.5c-1.2,1.1-1.8,2.4-1.8,4
	c0,1.2,0.4,2.4,0.9,3.4c1,1.8,2.2,3.4,3.6,4.8c1.2,1.3,2.6,2.5,4.2,3.3c1.4,0.7,2.9,1.2,4.4,1.7c0.8,0.2,1.6,0.4,2.5,0.2
	c0.9-0.2,1.7-0.6,2.5-1.2c0.4-0.3,0.7-0.6,0.9-1c0.3-0.6,0.4-1.3,0.4-1.9c0-0.4-0.1-0.6-0.5-0.8c-1.1-0.5-2.2-1.1-3.3-1.6
	c-0.2-0.1-0.5-0.2-0.8-0.3c-0.3-0.1-0.5,0-0.8,0.3c-0.5,0.6-1,1.2-1.5,1.9c-0.3,0.4-0.5,0.4-1,0.2c-0.4-0.2-0.7-0.3-1.1-0.5
	c-2.3-1.1-4.1-2.9-5.4-5.1c-0.3-0.5-0.3-0.7,0.1-1.1c0.5-0.5,0.9-1,1.2-1.6c0.1-0.3,0.1-0.5,0-0.8c-0.5-1.2-1-2.4-1.5-3.5
	c-0.1-0.2-0.2-0.4-0.3-0.5c-0.2-0.3-0.4-0.4-0.7-0.3c-0.2,0-0.3,0-0.4,0C17.8,14.2,17.6,14.2,17.5,14.2z"
          />
        </svg>
      );
    case "mail":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M24,7.8c5.7,0,11.5,0,17.2,0c0.4,0,0.8,0.1,1.1,0.3c0.5,0.3,0.9,0.8,1.3,1.3c0.3,0.4,0.3,0.9,0.3,1.4c0,0.4-0.1,0.7-0.5,1
	c-5.8,4.5-11.6,9.1-17.5,13.6c-0.4,0.3-0.9,0.7-1.4,0.9c-0.7,0.5-1.5,0.5-2.2,0c-0.6-0.4-1.2-0.8-1.8-1.2c-4.7-3.7-9.5-7.3-14.2-11
	C5.7,13.5,5,13,4.3,12.4c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.5,0-1,0-1.5c0-0.5,0.1-0.9,0.4-1.2C4.8,9,5.2,8.6,5.6,8.3
	C6,7.9,6.4,7.8,6.9,7.8C12.6,7.8,18.3,7.8,24,7.8z M28.7,26.4c-0.3,0.2-0.6,0.5-0.9,0.7c-0.9,0.7-1.8,1.4-2.9,1.8
	c-0.9,0.3-1.7,0.4-2.6,0.1c-0.9-0.3-1.7-0.9-2.5-1.4c-0.4-0.3-0.7-0.5-1.1-0.8c-4.1,3.6-8.3,7.3-12.5,11c11.7,0,23.4,0,35.1,0
	C37.2,33.9,32.9,30.1,28.7,26.4z M30.7,24.8c4.4,3.9,8.8,7.8,13.2,11.7c0-0.2,0.1-0.3,0.1-0.4c0-7.2,0-14.4,0-21.7
	C39.5,17.9,35.1,21.3,30.7,24.8z M4.1,36.2c4.2-3.7,8.4-7.4,12.6-11.1c-4.2-3.3-8.4-6.5-12.6-9.8C4.1,22.3,4.1,29.2,4.1,36.2z"
          />
        </svg>
      );
    case "QR":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M6,22h16V6H6V22z M10,10h8v8h-8V10z M6,42h16V26H6V42z M10,30h8v8h-8V30z M26,6v16h16V6H26z M38,18h-8v-8h8V18z M38,38h4v4
	h-4V38z M26,26h4v4h-4V26z M30,30h4v4h-4V30z M26,34h4v4h-4V34z M30,38h4v4h-4V38z M34,34h4v4h-4V34z M34,26h4v4h-4V26z M38,30h4v4
	h-4V30z"
          />
        </svg>
      );
    case "Share":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="48px"
          height="48px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M45.9,38.5c-0.1,0.4-0.1,0.8-0.2,1.2c-0.2,0.6-0.4,1.1-0.6,1.6c-0.3,0.7-0.8,1.3-1.3,1.8c-0.6,0.6-1.3,1.1-2.1,1.4
	c-1.5,0.7-3,0.7-4.5,0.2c-0.9-0.3-1.7-0.8-2.4-1.4c-0.6-0.5-1-1.1-1.4-1.8c-0.5-1-0.8-2-0.9-3.1c0-0.1,0-0.2,0-0.3
	c-0.6-0.1-1.1-0.2-1.7-0.4c-1.1-0.2-2.2-0.5-3.3-0.7c-1.1-0.2-2.2-0.5-3.2-0.7c-1-0.2-2-0.4-3.1-0.7c-1.1-0.2-2.2-0.5-3.3-0.7
	c-0.5-0.1-0.9-0.2-1.3-0.3c-0.2,0.3-0.4,0.7-0.6,1c-0.2,0.3-0.4,0.6-0.6,0.9c-0.4,0.4-0.8,0.8-1.2,1.2c-0.3,0.3-0.7,0.5-1.1,0.7
	c-0.7,0.4-1.4,0.6-2.1,0.8c-0.8,0.2-1.5,0.2-2.3,0.1c-0.8-0.1-1.5-0.3-2.2-0.7C5.8,38,5.2,37.6,4.6,37c-1.4-1.4-2.3-3.2-2.5-5.2
	c-0.1-1.5,0.1-3,0.7-4.4c0.4-0.9,0.9-1.6,1.6-2.3c0.5-0.5,1.1-0.9,1.7-1.3c0.7-0.4,1.4-0.6,2.2-0.8c0.8-0.2,1.7-0.2,2.5-0.1
	c0.7,0.1,1.5,0.3,2.2,0.7c0.6,0.3,1.2,0.8,1.8,1.3c0,0,0,0,0.1,0c0.5-0.5,1-1,1.5-1.5c0.6-0.6,1.2-1.2,1.8-1.8
	c0.5-0.4,0.9-0.9,1.3-1.4c0.7-0.7,1.4-1.4,2.1-2.1c0.4-0.4,0.9-0.9,1.3-1.3c0.5-0.5,1-1,1.5-1.5c0.4-0.5,0.9-0.9,1.3-1.4
	c0.2-0.2,0.3-0.3,0.5-0.5c-0.4-0.7-0.7-1.4-0.9-2.2C25,9.9,25,8.5,25.5,7.1c0.4-1.1,1-2.1,1.8-2.9c0.6-0.6,1.4-1.1,2.2-1.4
	c1.4-0.5,2.7-0.6,4.1-0.1C34.5,2.9,35.3,3.4,36,4c0.6,0.6,1.2,1.3,1.6,2.1c0.8,1.5,1,3.2,0.7,4.8c-0.2,1-0.6,2-1.2,2.8
	c-0.4,0.5-0.8,1-1.3,1.4c-1.3,1-2.7,1.5-4.3,1.4c-0.8,0-1.6-0.2-2.4-0.5c-0.4-0.2-0.7-0.4-1.1-0.6c-0.1,0.1-0.3,0.3-0.4,0.5
	c-0.3,0.3-0.5,0.6-0.8,0.9c-0.5,0.6-1.1,1.2-1.6,1.8c-0.6,0.7-1.2,1.4-1.9,2.1c-0.4,0.5-0.8,0.9-1.2,1.4c-0.6,0.7-1.3,1.4-1.9,2.1
	c-0.4,0.5-0.8,0.9-1.2,1.4c-0.6,0.7-1.2,1.4-1.8,2.1C17.1,27.8,17,28,17,28.1c0,0.1-0.1,0.1,0,0.2c0.2,0.8,0.4,1.5,0.4,2.3
	c0,0.1,0.1,0.2,0.2,0.2c0.5,0.1,0.9,0.2,1.4,0.4c0.9,0.2,1.8,0.5,2.7,0.8c0.7,0.2,1.5,0.4,2.2,0.6c0.9,0.2,1.7,0.5,2.6,0.7
	c0.8,0.2,1.5,0.4,2.3,0.7c0.9,0.2,1.7,0.5,2.6,0.7c0.5,0.2,1.1,0.3,1.6,0.5c0.1,0,0.1,0,0.2,0c0.2-0.4,0.4-0.8,0.6-1.1
	c0.3-0.4,0.6-0.9,0.9-1.3c0.6-0.7,1.3-1.1,2.1-1.5c1-0.5,2.1-0.7,3.2-0.6c0.6,0.1,1.2,0.2,1.7,0.4c0.8,0.3,1.6,0.8,2.2,1.4
	c0.5,0.5,1,1.1,1.3,1.7c0.4,0.9,0.7,1.8,0.8,2.7c0,0.1,0,0.1,0,0.2C45.9,37.7,45.9,38.1,45.9,38.5z"
          />
        </svg>
      );
    case "upload":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="48px"
          height="48px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M10.7,4.4c6,0,12,0,18.1,0c0.5,0.4,1,0.8,1.5,1.3c1.5,1.5,2.9,3,4.5,4.4c0.8,0.8,1.1,1.6,1.1,2.7c0,3,0,6,0,8.9
	c0,0.5,0.2,1,0.4,1.4c1.2,1.8,2.5,3.6,3.7,5.4c1.8,2.6,0.3,5.8-2.2,6.6c-0.2,0.1-0.5,0.4-0.5,0.7c-0.1,0.8-0.1,1.6,0,2.5
	c0.1,2.1-1.3,3.9-3.3,4.3c-1.1,0.2-2.3,0.2-3.4,0.2c-1.3,0-2.4-0.5-3.4-1.4c-0.3-0.3-0.8-0.5-1.1-0.5c-4.8,0-9.6-0.1-14.4,0
	c-2.2,0.1-3.7-1.4-3.7-3.7c0-9.7,0-19.3,0-29c0-1,0.2-2,0.9-2.6C9.4,5.1,10.1,4.8,10.7,4.4z M25.9,36.8c0.2-1.4,0.2-1.5-0.4-1.7
	c-2.9-1.1-3.9-4.1-2.1-6.7c1-1.5,2.1-3,3.1-4.5c0.3-0.4,0.6-0.9,0.9-1.3c1-1.7,2.3-2.7,4.3-2.8c0-1.8,0-3.5,0-5.3
	c-1.2,0-2.4,0-3.5,0c-1.6-0.1-2.4-0.9-2.4-2.5c0-1.2,0-2.3,0-3.4c-4.7,0-9.2,0-13.8,0c0,9.5,0,18.9,0,28.3
	C16.7,36.8,21.3,36.8,25.9,36.8z M34,32.1c0.8,0,1.5,0.1,2.1,0c0.4-0.1,0.9-0.3,1.1-0.6c0.1-0.3,0-0.9-0.2-1.2
	c-1.4-2.1-2.9-4.2-4.3-6.2c-0.8-1.1-1.4-1.1-2.2,0c-1.4,2-2.8,4-4.2,6c-0.2,0.4-0.5,0.9-0.4,1.2c0.1,0.6,0.8,0.7,1.4,0.7
	c0.7,0,1.3,0,2,0c0,2.1,0,4.2,0,6.2c0,0.9,0.3,1.1,1.2,1.2c0.6,0,1.2,0,1.9,0c1.3,0,1.6-0.3,1.6-1.6C34,35.9,34,34,34,32.1z"
          />
        </svg>
      );
    case "broadcast":
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="48px"
          height="48px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M38.8,43.2c-8.6,0-17.1,0-25.7,0c0-12.4,0-24.9,0-37.3c0.2,0,0.3,0,0.5,0c4.6,0,9.3,0,13.9,0c0.8,0,1.7,0,2.5,0
	c0.1,0,0.3-0.1,0.4-0.2c0.2-0.3,0.5-0.4,0.9-0.4c1.7,0,3.4,0,5.1,0c0.2,0,0.6,0.1,0.7,0.2c0.2,0.4,0.6,0.3,0.9,0.3
	c1.2,0,1.8,0.6,1.8,1.8c0,11.2,0,22.5,0,33.7C39.9,42.2,39.6,42.8,38.8,43.2z M27.6,26.6c1.4,0,2.8,0,4.2,0c0.6,0,0.8-0.3,0.8-0.8
	c0-1,0-2,0-3c0-0.6-0.3-0.9-0.9-0.9c-1.3,0-2.5,0-3.8,0c-0.1,0-0.3,0-0.4,0c0-1.4,0-2.8,0-4.2c0-0.5-0.3-0.8-0.8-0.8c-1,0-2,0-3,0
	c-0.5,0-0.8,0.3-0.8,0.8c0,1.3,0,2.5,0,3.8c0,0.1,0,0.3,0,0.4c-0.2,0-0.3,0-0.4,0c-1.3,0-2.5,0-3.8,0c-0.5,0-0.8,0.3-0.8,0.8
	c0,1,0,2.1,0,3.1c0,0.5,0.3,0.7,0.8,0.7c1.3,0,2.6,0,3.9,0c0.1,0,0.3,0,0.4,0c0,0.2,0,0.3,0,0.4c0,1.3,0,2.6,0,3.9
	c0,0.4,0.3,0.7,0.7,0.7c1.1,0,2.1,0,3.2,0c0.5,0,0.7-0.3,0.7-0.8c0-1,0-2,0-2.9C27.6,27.5,27.6,27.1,27.6,26.6z M32.2,7.1
	c0,2.9,0,5.7,0,8.6c0.5-0.2,0.9-0.4,1.3-0.6c0.3-0.2,0.7-0.2,1,0c0.3,0.2,0.7,0.3,1.1,0.5c0-2.9,0-5.7,0-8.5
	C34.5,7.1,33.3,7.1,32.2,7.1z M8.7,43.2c-0.7-0.4-1-1-1-1.8c0-11.3,0-22.5,0-33.8c0-0.4,0.1-0.8,0.3-1.1c0.2-0.4,0.6-0.6,1.1-0.6
	c0.7,0,1.5,0,2.2,0c0,12.5,0,24.9,0,37.3C10.5,43.2,9.6,43.2,8.7,43.2z M10.1,34.4c0-0.8,0-1.5,0-2.3c0-0.4-0.2-0.6-0.5-0.7
	c-0.4,0-0.6,0.2-0.6,0.7c0,1.5,0,3,0,4.5c0,0.4,0.2,0.6,0.5,0.7c0.4,0,0.6-0.2,0.6-0.7C10.1,35.9,10.1,35.1,10.1,34.4z M10.1,12
	c0-0.3,0-0.5,0-0.8c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.5,0.2-0.5,0.5c0,0.5,0,1,0,1.5c0,0.3,0.2,0.6,0.6,0.5c0.3,0,0.5-0.2,0.5-0.6
	C10.1,12.5,10.1,12.2,10.1,12z M10.1,39.8c0-0.3,0-0.5,0-0.8c0-0.3-0.3-0.6-0.6-0.5c-0.3,0-0.5,0.2-0.5,0.5c0,0.5,0,1,0,1.5
	c0,0.3,0.2,0.5,0.6,0.5c0.3,0,0.5-0.2,0.5-0.5C10.1,40.3,10.1,40.1,10.1,39.8z"
          />
        </svg>
      );
    case "download":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill={color}>
          <path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q17-72 85-137t145-65q33 0 56.5 23.5T520-716v242l64-62 56 56-160 160-160-160 56-56 64 62v-242q-76 14-118 73.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h480q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-48-22-89.5T600-680v-93q74 35 117 103.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H260Zm220-358Z" />
        </svg>
      )
    default:
      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="48px"
          height="48px"
          viewBox="0 0 48 48"
        >
          <path
            fill={color}
            d="M38.3,25.9c0.1-0.6,0.1-1.2,0.1-1.9c0-0.6,0-1.3-0.1-1.9l4.1-3.2c0.4-0.3,0.5-0.8,0.2-1.2l-3.8-6.6
	c-0.2-0.4-0.7-0.6-1.2-0.4l-4.8,1.9c-1-0.8-2.1-1.4-3.2-1.9l-0.7-5.1c-0.1-0.5-0.5-0.8-1-0.8h-7.7c-0.5,0-0.9,0.3-0.9,0.8l-0.7,5.1
	c-1.2,0.5-2.3,1.1-3.2,1.9l-4.8-1.9c-0.4-0.2-0.9,0-1.2,0.4l-3.8,6.6c-0.2,0.4-0.2,0.9,0.2,1.2l4.1,3.2c-0.1,0.6-0.2,1.3-0.2,1.9
	s0,1.3,0.1,1.9L5.7,29c-0.4,0.3-0.5,0.8-0.2,1.2l3.8,6.6c0.2,0.4,0.7,0.6,1.2,0.4l4.8-1.9c1,0.8,2.1,1.4,3.2,1.9l0.7,5.1
	c0.1,0.5,0.5,0.8,1,0.8h7.7c0.5,0,0.9-0.3,0.9-0.8l0.7-5.1c1.2-0.5,2.3-1.1,3.2-1.9l4.8,1.9c0.4,0.2,0.9,0,1.2-0.4l3.8-6.6
	c0.2-0.4,0.1-0.9-0.2-1.2L38.3,25.9z M24,31.2c-4,0-7.2-3.2-7.2-7.2s3.2-7.2,7.2-7.2s7.2,3.2,7.2,7.2S28,31.2,24,31.2z"
          />
        </svg>
      );
  }
}

export default Iconos;
