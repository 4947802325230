import React, { lazy, Suspense, useEffect, useState } from 'react'
import Cargando from '../Externo/Cargando';
import StudyItem from '../../Class/study';
const Visor = lazy(() => import('../NuevoVisor/Visor'));


//EstudioVisor


function Local(props) {
  const [loading, setloading] = useState(true)
  useEffect(() => {
    const { id } = props.match.params
    StudyItem.GetStudy(id)
    .then(res=>{
      window.sessionStorage.setItem('EstudioVisor',JSON.stringify(res))
      setloading(false)
    })
    return () => {
      //second
    }
  }, [])
  if (loading) {
    return (
      <Cargando mensaje="Inicializando Visor" />
    )
  }
  return (
    <Suspense fallback={<Cargando mensaje="Inicializando Visor" />}>
      <Visor {...props} />
    </Suspense>
  )
}

export default Local