import React from "react";
import { AccionDrawer, AccionSistema } from "./SideBar";
import "./Pies.scss";

function Pies(props) {
  return (
    <div id="Pies">
      <AccionDrawer
        {...props}
        onClick={props.onOpen}
        componente={0}
        icon="calendario"
        color="white"
      />
      <AccionDrawer
        {...props}
        onClick={props.onOpen}
        componente={1}
        icon="filtro"
        color="white"
      />
      {props.ListaEstudios.error ? (
        <AccionSistema icon="noconexion" color="white" />
      ) : (
        <AccionSistema icon="cocnexion" color="white" />
      )}
      <AccionSistema
        onClick={props.onSync}
        icon="sync"
        color="white"
        girar={props.ListaEstudios.sync}
      />
      <AccionSistema icon="logout" color="white" 
      onClick={()=>{window.location.href="/login"}}/>
    </div>
  );
}

export default Pies;
