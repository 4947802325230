import moment from "moment"
import jwt from "jsonwebtoken"
let s = "Medicaltec3101"
let U 
class Estudio{
    constructor(obj){
        this.ID = obj.ID
        this.FECHA = obj.FECHA
        this.NOMBRE = obj.NOMBRE
        this.SEXO = obj.SEXO
        this.PAS_ID = obj.PAS_ID
        this.EST_UID = obj.EST_UID
        this.SERIES = obj.SERIES
    }
    getmodalidad(){
        let mod
        switch (this.SERIES[0].MODALIDAD) {
          case "CT":
            mod = "Tomografia";
            break;
          case "MR":
            mod = "Resonancia";
            break;
          case "US":
            mod = "Ecografia";
            break;
          case "CR":
            mod = "Rayos x";
            break;
          case "DX":
            mod = "Rayos x";
            break;
          case "MG":
            mod = "Mamografia";
            break;
          default:
            mod = this.SERIES[0].MODALIDAD;
            break;
        }
        return mod
    }
    getmodalidadfull(){
        let mod
        switch (this.SERIES[0].MODALIDAD) {
          case "CT":
            mod = "Tomografia";
            break;
          case "MR":
            mod = "Resonancia";
            break;
          case "US":
            mod = "Ecografia";
            break;
          case "CR":
            mod = "Rayos x";
            break;
          case "DX":
            mod = "Rayos x";
            break;
          case "MG":
            mod = "Mamografia";
            break;
          default:
            mod = this.SERIES[0].MODALIDAD;
            break;
        }
        return {dicom:this.SERIES[0].MODALIDAD,texto:mod}
    }
    getsexo(){
        return this.SEXO
    }
    colorsexo(){
        let color
        switch (this.SEXO) {
            case "M":
                color="#025185"
                break;
            case "F":
                color="#ed0558"
                    break;
            default:
                color="#9ea7aa"
                break;
        }
        return color
    }
    getnombre(){
        let array = this.NOMBRE
        let nombre = ''
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if(element === "^"){
                nombre = nombre + " "
            }else{
                nombre = nombre + element
            }
        }
        return nombre
    }
    getfecha(){
        return moment(this.FECHA,"YYYYMMDD").format("DD/MM/YYYY")
    }
    filtrar(filtro){
        if(this.NOMBRE.toUpperCase().indexOf(filtro.toUpperCase()) > -1 || this.PAS_ID.indexOf(filtro.toUpperCase()) > -1){
            return true
        }else{
            return false
        }
    }
    filtrarmod(mods){
        if(mods.indexOf(this.getmodalidadfull().dicom) > -1){
            return false
        }else{
            return true
        }
    }
    ShareWP(envio){
        let token = jwt.sign({ID:this.ID},s,{expiresIn:`${envio.tiempo}h`})
        let mensaje = escribirmensaje(token,this.getmodalidad(),this.getnombre())
        if(envio.numero !== ""){
            window.open(`https://wa.me/${envio.prefijo}${envio.numero}?text=${mensaje}`,'_blank')
        }else{
            alert("numero telefonico invalido")
        }
        return true
    }
    ShareMail(envio){
        return new Promise((Pres,Prej)=>{
            let token = jwt.sign({ID:this.ID},s,{expiresIn:`${envio.tiempo}h`})
            let configuracion = JSON.parse(window.localStorage.getItem('ConfiguracionGeneral'))
            let data = {
                destino:envio.direccion,
                data:`${configuracion.ServicorExterno}/externo/${token}`,
                nombre:this.getnombre(),
                modalidad:this.getmodalidad()
            }
            if(envio.direccion !== ""){
                Pres(data)
            }else{
                Prej(false)
            }
        })
    }
    ShareQR(){
        let token = jwt.sign({ID:this.ID},s,{expiresIn:"48h"})
        let url = `${U}/externo/${token}`
        return(url)
    }
    ShareWPcontacto(contacto){
        let token = jwt.sign({ID:this.ID},s,{expiresIn:"48h"})
        let mensaje = escribirmensaje(token,this.getmodalidad(),this.getnombre())
        if(contacto.numero !== ""){
            window.open(`https://wa.me/${contacto.prefijo}${contacto.telefono}?text=${mensaje}`,'_blank')
        }else{
            alert("numero telefonico invalido")
        }
        return true
    }
    ShareMailcontacto(contacto){
        let token = jwt.sign({ID:this.ID},s,{expiresIn:"48h"})
        console.log(contacto,this.ID)
        let configuracion = JSON.parse(window.localStorage.getItem('ConfiguracionGeneral'))
        let data = {
            destino:contacto.correo,
            data:`${configuracion.ServicorExterno}/externo/${token}`,
            nombre:this.getnombre(),
            modalidad:this.getmodalidad()
        }
        return data
    }
    Abririmagenes(filtros){
        window.sessionStorage.setItem("filtrosmain",JSON.stringify({fecahs:filtros.RangoFechasSt,mod:filtros.FiltroModalidades}))
        window.sessionStorage.setItem('EstudioVisor',JSON.stringify(this))
    }
}
export default Estudio

function escribirmensaje(token,modalidad,NOMBRE){
    let configuracion = JSON.parse(window.localStorage.getItem('ConfiguracionGeneral'))
    let mensaje = `${configuracion.NombreInstitucion}:%0AEstudio de ${modalidad.toUpperCase()} del paciente ${NOMBRE}:%0A
    ${configuracion.ServicorExterno}/externo/${token} - %0APara poder habilitar el estudio responda este mensaje`
    return mensaje
}