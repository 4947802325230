export const initialState = {
    view: 'dashboard'
}
export function reducer(state, action){
    console.log('reducer', action)
    switch (action.type) {
        case 'SET_VIEW':
            return {
                ...state,
                view: action.payload
            }
        default:
            return state
    }
}